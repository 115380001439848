import { Button, Form, Modal, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import price_header_blue from "../../../assets/price_header_blue.png";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { useLocation } from "wouter";
import { AuthContext } from "../../../auth/AuthContext";
import { PoweroffOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { packageRepo, userRepo, userSubcriptionRepo } from "../../../store";
import moment from "moment";

const AddPackageStep3 = ({
  next,
  prev,
  step3Data,
  setStep3Data,
  step2Data,
}) => {
  const { t } = useTranslation();
  const [matter, setMatter] = useState();
  const [quantity, setQuantity] = useState();
  const [chosenSubjects, setChosenSubjects] = useState();
  const [matterId, setMatterId] = useState([]);
  const [active, setActive] = useState([]);
  const [userId, setUserId] = useState([]);
  const [subjectsQuantity, setSubjectsQuantity] = useState();
  const [maxSubjects, setMaxSubjects] = useState(false);
  const [loadings, setLoadings] = useState();
  const [cleanup, setCleanup] = useState();

  const paquete = step2Data;
  const dateNow = new Date().toISOString().slice(0, 10);
  const [duration, setDuration] = useState();
  const [price, setPrice] = useState();
  const [subscriptionStart, setSubscriptionStart] = useState();

  const [subscriptionEnd, setSubscriptionEnd] = useState();

  const changeStyle = (id, i, isActive) => {
    if (!isActive) {
      active[i] = true;
      matterId.push(id);
      setQuantity(matterId.length);
    } else {
      active[i] = false;
      var index = matterId.indexOf(id);
      if (index === -1) {
        var indexS = matterId.indexOf(id.toString());
        matterId.splice(indexS, 1);
      } else {
        var indexS = matterId.indexOf(id);
        matterId.splice(indexS, 1);
      }

      setQuantity(matterId.length);
    }

    setMatterId(
      matterId.filter(
        (item) => item !== null || item !== "" || item !== undefined
      )
    );
  };

  useEffect(() => {
    onload();
    return () => {
      setCleanup();
    };
  }, []);

  const onload = async () => {
    const response = await packageRepo.getPackage(paquete);
    message.info(
      "Paquete " +
        response.data.data.name +
        " duración " +
        response.data.data.duration +
        "" +
        t("days") +
        " $ " +
        response.data.data.price +
        " (CLP)",
      0
    );
    setPrice(response.data.data.price);
    setDuration(response.data.data.duration);
    
    setSubscriptionStart(dateNow);    

    var dateAddEnd = moment(dateNow).add(parseInt(response.data.data.duration), "days");
    
    setSubscriptionEnd(dateAddEnd._d.toISOString().slice(0, 10));

    const maxSubjects = await packageRepo.getMaxSubsjects(
      response.data.data.standard_test_id
    );

    localStorage.getItem("matters");
    localStorage.getItem("active");
    setSubjectsQuantity(response.data.data.subjects_quantity);
    const response2 = await packageRepo.getSubjects(paquete);
    setQuantity(subjectsQuantity);

    if (localStorage.getItem("active") !== null) {
      var activeMatterId;
      var activeMatterId = localStorage.getItem("matters").split(",");
      var activeMatter = localStorage.getItem("active").split(",");

      setMatterId(activeMatterId);
      setQuantity(activeMatterId.length);
      for (var i = 0; i < activeMatter.length; i++) {
        if (activeMatter[i] === "true") {
          active[i] = true;
          setActive(active);
        } else {
          active[i] = false;
          setActive(active);
        }
      }
    } else {
      if (
        response.data.data.subjects_quantity <
        maxSubjects.data.data[0].maxSubjects
      ) {
        for (var i = 0; i < response2.data.data.data.length; i++) {
          active[i] = false;
          setActive(active);
        }
      } else {
        active[i] = true;
        for (var i = 0; i < response2.data.data.data.length; i++) {
          matterId.push(response2.data.data.data[i].id);
        }
        setQuantity(matterId.length);
        setActive(active);
        setMaxSubjects(true);
      }
    }
    setMatter({ matter: response2.data.data.data });
    setCleanup(false);
  };

   const onSend = async () => {
  if (
    matterId.length === subjectsQuantity ||
    chosenSubjects === subjectsQuantity
  ) {
    message.destroy();
    setLoadings(true);
     Modal.info({
      title: t("info"),
      content: t("platformPayment"),
    });
    userRepo.showUser().then(
      ({ data }) => {
      userSubcriptionRepo
            .createUserSubcription({
              payment_method: "Mercado Pago",
              payment_amount: price,
              payment_tax: price,
              payment_date: subscriptionStart,
              subscription_start: subscriptionStart,
              subscription_end: subscriptionEnd,
              trial_duration: null,
              trial_started_at: null,
              unsubscribe_request_date: subscriptionEnd,
              status: "Pending_payment",
              package_id: paquete,
              user_id: data.id,
              subject_id: matterId,
            }).then((response3) => {
              window.open(response3.data.data[1], "_blank");
              setLoadings(false);
            });
    },
    (error) => {
      setLoadings(false);
      Modal.error({
        title: "Error message",
        content: error.response.data.message,
      });
    }
    );
  } else {
    Modal.info({
      title: t("info"),
      content: (
        <div>
          <p>
            {t("msgSubcription13")} {subjectsQuantity} {t("msgSubcription23")}
          </p>
        </div>
      ),
      onOk() {},
    });
  }
  
  };

  const finishStep3 = (e) => {
    message.destroy();
    setStep3Data(matterId);
  };

  return (
    <Form onFinish={finishStep3}>
      <h1 className="mb-15 mt-15 text-center">{t("titleStep4")}</h1>
      <div className="page-controls max-width">
        <div className="mt-60">
         <h3 className="mb-15 mt-15 text-center">
            {t("msgSubcription13")} {subjectsQuantity} {t("msgSubcription23")}
          </h3>
          <div className="matters-grid">
            {typeof matter === "undefined" ? (
              <h1>Loading....</h1>
            ) : (
              matter.matter.map((matters, i) =>
                active[i] === false ? (
                  <div className="table-item" key={matters.id}>
                    <div className="price-table-header">
                      <img
                        src={price_header_blue}
                        alt={t("alt")}
                        className="table-header-image"
                      />

                      <div className="table-header-content">
                        <Subtitle
                          h4
                          content={matters.name}
                          customClass={"c-white"}
                        />
                        <Subtitle
                          h4
                          content={matters.description}
                          customClass={"c-white"}
                        />
                      </div>

                      <div className="price-table-list">
                        <Form.Item name="paquete">
                          <Button
                            name="paquete"
                            value={matters.id}
                            onClick={() => changeStyle(matters.id, i)}
                          >
                            {t("selection")}
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="table-item-selection" key={matters.id}>
                    <div className="price-table-header">
                      <img
                        src={price_header_blue}
                        alt={t("alt")}
                        className="table-header-image"
                      />

                      <div className="table-header-content">
                        <Subtitle
                          h4
                          content={matters.name}
                          customClass={"c-white"}
                        />
                        <Subtitle
                          h4
                          content={matters.description}
                          customClass={"c-white"}
                        />
                      </div>
                      <div className="price-table-list">
                        {maxSubjects === true ? (
                          <Button type="primary" disabled>
                            {t("btndeseleccionado")}
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => changeStyle(matters.id, i, true)}
                          >
                            {t("btndeseleccionado")}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
      <div className="container-buttons-ChangePackage">
      <Button
          onClick={() => {
            prev();
          }}
        >
          {t("btnback")}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSend}
          icon={<PoweroffOutlined />}
          loading={loadings}
        >
          {t("btncontinue")}
        </Button>
      </div>
    </Form>
  );
};

export default AddPackageStep3;
