import { useState } from "react";
import { Steps } from "antd";
import "./RegisterPackage.scss";
import RegisterSubcriptionPackageStep2 from "./RegisterSubcriptionPackageStep2";
import RegisterSubcriptionPackageStep3 from "./RegisterSubcriptionPackageStep3";
import RegisterSubcriptionPackageStep4 from "./RegisterSubcriptionPackageStep4";
import { useTranslation } from "react-i18next";
const { Step } = Steps;

const RegisterSubcription = () => {
  const { t } = useTranslation();
  const [step2Data, setStep2Data] = useState(null);
  const [step3Data, setStep3Data] = useState(null);
  const [step4Data, setStep4Data] = useState(null);
  const [current, setCurrent] = useState(2);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const steps = [
    {
      title: t("registro"),
    },
    {
      title: "E-mail",
    },
    {
      title: t("paquete"),
      content: (
        <RegisterSubcriptionPackageStep2
          next={next}
          step2Data={step2Data}
          setStep2Data={setStep2Data}
        />
      ),
    },
    {
      title: t("materia"),
      content: (
        <RegisterSubcriptionPackageStep3
          next={next}
          prev={prev}
          step3Data={step3Data}
          setStep3Data={setStep3Data}
          step2Data={step2Data}
        />
      ),
    },
    {
      title: t("direccion"),
      content: (
        <RegisterSubcriptionPackageStep4
          next={next}
          prev={prev}
          step4Data={step4Data}
          setStep4Data={setStep4Data}
          step2Data={step2Data}
          step3Data={step3Data}
        />
      ),
    },
    {
      title: t("Tarjeta"),
    },
  ];

  return (
    <div className="register_container">
      <div className="box-white">
        <Steps
          current={current}
          style={{ marginRight: 100 }}
          responsive={"600px"}
        >
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div>{steps[current].content}</div>
      </div>
    </div>
  );
};

export default RegisterSubcription;
