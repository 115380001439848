import Repository from './repository.js'
export default class UserSubcriptionRepository extends Repository {
  constructor (apis) {
    super(apis)
  }

  createUserSubcription (params = {}) {
    const {
      payment_method,
      payment_amount,
      payment_tax,
      payment_date,
      subscription_start,
      subscription_end,
      trial_duration,
      trial_started_at,
      unsubscribe_request_date,
      package_id,
      status,
      user_id,
      subject_id,
    } = params

    return this.$axios.post('api/user-subscriptions', {
      payment_method: payment_method,
      payment_amount: payment_amount,
      payment_tax: payment_tax,
      payment_date: payment_date,
      subscription_start: subscription_start,
      subscription_end: subscription_end,
      trial_duration: trial_duration,
      trial_started_at: trial_started_at,
      unsubscribe_request_date: unsubscribe_request_date,
      package_id: parseInt(package_id),
      status: status,
      user_id: user_id,
      subject_id: subject_id,
    })
  }

  createUserSubcriptionIncrease (params = {}) {
    const {
      id
    } = params

    return this.$axios.post('api/user-subscriptions-increase', {     
      id: id     
    })
  } 
  
  editFeedBack(params = {}) {
    const {id, feedback} = params                 
    return this.$axios            
        .post('api/user-subscriptions/feedback',              
        {  id: id, feedback:feedback })
        .then(({data}) => data)
}

  getUserSubcription () {
    return this.$axios
      .get('api/user-subscriptions')
      .then(({ data }) => data)
  }

  getUserSubcriptionDetails () {
    return this.$axios
      .get('api/user-subscriptions/details')
      .then(({ data }) => data)
  }

  async getUserSubcriptionIncrease () {
    return await this.$axios
      .get('api/user-subscriptions/increase-checkout-link')
      .then(({ data }) => data)
  }

  getUserSubcriptionByIdIncrease (params = {}) {
    return   this.$axios
      .get('api/user-subscriptions/subscription-id/increase-checkout-link',
      {
        params: params
      })
      .then(({ data }) => data)
  }

  async queryStatusIncrease (increase_subscription_id) {
    return await this.$axios
      .get('api/user-subscriptions/increase/' + increase_subscription_id)
      .then(({ data }) => data)
  }

  status (id) {
    return this.$axios.post('api/user-subscriptions/set-status-pending', {
      id: id
    })
  }

  statusSubscription(params = {}) {    
    return this.$axios.get('api/user-subscriptions/set-status', {
      params: params
    })
  } 
  
  notificationTrial(params = {}) {    
    return this.$axios.get('api/user-subscriptions/notification-trial', {
      params: params
    })
  }
}
