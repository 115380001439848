import "./PageFooter.scss";
import moment from "moment";
import logo from "../../../assets/logo_white.svg";
import ModalAntd from "../../Atoms/Modal/ModalAntd";
import {
  PhoneOutlined,
  EnvironmentOutlined,
  MailOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const PageFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="page-footer_container">
      <div className="page-footer max-width">
        <div className="col-footer">
          <div className="page-footer_logo">
            <img src={logo} alt="Logo" />
            <div className="row_footer_contact">
            <p className="montserrat-15">{t("derecho")}</p>
            <p className="montserrat-15 mt-5">Ikenga {moment().year()}</p>
            <div className="montserrat-15">
              {" "}
              <ModalAntd />
            </div>
            </div>
          </div>
        </div>
        <div className="col-footer">
          <div className="row_footer_contact">
            <p className="montserrat-18"> {t("contact")} </p>
          </div>
          <div className="row_footer_contact">
            <div className="col-footer">
              <div className="row_footer_p">
                <p className="montserrat-15 mt-10">
                  {" "}
                  <MailOutlined /> ikenga@hexasystems.com{" "}
                </p>
              </div>
              <div className="row_footer_p">
                <p className="montserrat-15 mt-10">
                  {" "}
                  <PhoneOutlined /> +56 (9) 8831.4232{" "}
                </p>
              </div>
            </div>
            <div className="col-footer">
              <div className="row_footer_p">
                <p className="montserrat-15 mt-10" style={{whiteSpace: "initial"}}>
                  {" "}
                  <EnvironmentOutlined /> Jorge Matte Gormaz 1400, #603,
                  Providencia, Santiago 7510403, Chile
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-footer">
          <div className="row_footer_contact">
            <p className="montserrat-18"> {t("follow")}  </p>
          </div>
          <div className="row_footer_contact">
            <div className="col-footer">
              <div className="row_footer_p">
                <a href="https://www.instagram.com/ikenga.app/">
                  <p className="montserrat-15">
                    <InstagramOutlined /> Instagram{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
