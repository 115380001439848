import { useState, useEffect, useContext } from "react";
import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import { Pie } from "@ant-design/charts";
import { Button, Card, message } from "antd";
import { useLocation } from "wouter";
import moment from "moment";
import "./Result.scss";
import { RightOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../auth/AuthContext";
import { useTranslation } from "react-i18next";
import { types } from "../../../types/types";



const Result = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const {
    control,
    typeTest,
    dispatchControl,
    dispatchType,
    result,
    dispatchResult,
  } = useContext(AuthContext);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [date, setDate] = useState();
  const [total, setTotal] = useState();
  const [totalCorrect, setTotalCorrect] = useState();
  const [totalIncorrect, setTotalIncorrect] = useState();
  const [totalUnanswered, setTotalUnanswered] = useState();
  const [score, setScore] = useState();
  const [loadings, setLoadings] = useState();

  useEffect(() => {
    window.onpopstate = (e) => {
      setLocation("/dashboard");
    };
  });

  useEffect(() => {
    message.destroy();
    onLoad();
    return () => {
      setLoadings();
    };
  }, []);

  const data = [
    {
      type: t("questionIncorrect"),
      value: Math.round((parseInt(result.totalIncorrect) * 100) / parseInt(result.total)),
    },
    {
      type: t("questionCorrect"),
      value: Math.round((parseInt(result.totalCorrect) * 100) / parseInt(result.total)),
    },

    {
      type: t("questionUnanswered"),
      value: Math.round((parseInt(result.totalUnanswered) * 100) / parseInt(result.total)),
    },
  ];

  const onLoad = () => {
    setDate(new Date(result.start).toLocaleDateString());
    setDateStart(new Date(result.start));
    setDateEnd(new Date(result.end));
    if (typeTest.type === "official") {
      setScore(result.score);
    } else {
      if (result.totalCorrect === 0) {
        setScore(0);
      } else {
        setScore(Math.round((100 * result.totalCorrect) / result.total));
      }
    }
    setTotalCorrect(Math.round(result.totalCorrect));
    setTotal(result.total);
    setTotalIncorrect(Math.round(result.totalIncorrect));
    setTotalUnanswered(Math.round(result.totalUnanswered));

    setLoadings(false);
  };

  const onSend = () => {
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: "",
        subjectName: "",
        subjectApprovalGrade:"",
        context: "",
        paqueteId: "",
        standartTestId: "",
        configuration: null,
        userTestId: "",
      },
    });
    dispatchType({
      type: types.typeTest,
      payload: {
        type: "",
      },
    });
    dispatchResult({
      type: types.result,
      payload: {
        start: "",
        end: "",
        score: "",
        total: "",
        totalCorrect: "",
        totalIncorrect: "",
        totalUnanswered: "",
      },
    });

    setLocation("/dashboard");
  };

  const config = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value} %",
      style: {
        textAlign: "center",
        fontSize: 16,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 16,
        },
        content: "Resultados",
      },
    },
  };

  return (
    <div className="max-width">
      <HeaderPage
        contentButton={t("contentButtonResult")}
        routeButton="/dashboard"
        contentTitle={t("contentTitleResult")}
      />

      <div>
      <Card title={t("contentTitleResult")} bordered={false}>
          <div className="row">
            <div className="col-result col-40">
              
                <div className="pxy-30 ">
                  <h3 className="c-black mb-10">
                    {" "}
                    {t("fecha")} {date}
                  </h3>
                  
                  <h3 className="c-black mb-10">
                    {" "}
                    {t("nota")} {score}
                    {typeTest.type === "official" ? null : "%"}
                  </h3>

                  <h3 className="c-black mb-10">
                    {t("tiempo")} :{" "}
                    {moment(dateEnd).diff(moment(dateStart), "hours") +
                      " : " +
                      (moment(dateEnd).diff(moment(dateStart), "minutes") %
                        60) +
                      " : " +
                      (moment(dateEnd).diff(moment(dateStart), "seconds") % 60)}
                  </h3>

                  <h3 className="c-black mb-10">
                    {" "}
                    {t("questionTotal")} : {total} 
                  </h3>

                  <h3 className="c-black mb-10">
                    {" "}
                    {t("questionCorrect")} : {totalCorrect}{" "}
                  </h3>

                  <h3 className="c-black mb-10">
                    {" "}
                    {t("questionIncorrect")} : {totalIncorrect}{" "}
                  </h3>

                  <h3 className="c-black mb-10">
                    {" "}
                    {t("questionUnanswered")} : {totalUnanswered}{" "}
                  </h3>
                  

                </div>
          
            </div>
            <div className="col-result col-60">
              
                <Pie className="pxy-20" {...config} />
              
            </div>
           
          </div>
          <div className=" pxy-30 text-center" >
            { total  >= control.subjectApprovalGrade  ?
                    <h3 className="text-center">
                    {" "}
                    {"Felicitaciones por haber superado tu examen "} {control.subjectName} {"exitosamente. Tu Nota es : "} {score}
                  </h3>
                  :
                  <h3 className="text-center">
                    {" "}
                    {"No te rindas sigue intentando. Tu Nota es : "} {score}
                  </h3>

                  }
            </div>
          </Card>
      </div>
     
      <div className="main-ensayos-buttons mt-40" style={{ float: "right" }}>
        <Button type="primary" onClick={onSend} icon={<RightOutlined />}>
          {t("btnfinalizar")}{" "}
        </Button>
      </div>
    </div>
  );
};

export default Result;
