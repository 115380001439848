import { Link, useRoute } from "wouter";
import { useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";

const ActiveLinkD = ({ href, type, children }) => {
  const [isActive] = useRoute(href);

  const { dispatchType } = useContext(AuthContext);

  const handleControl = () => {
    if (type !== "") {
      dispatchType({
        type: types.typeTest,
        payload: {
          type: type,
        },
      });
    }
  };

  return (
    <li className="main-menu_item ">
      <Link
        href={href}
        onClick={handleControl}
        className={isActive ? "active" : ""}
      >
        {children}
      </Link>
    </li>
  );
};

export default ActiveLinkD;
