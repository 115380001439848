import Repository from './repository.js'
export default class UserTestRepository extends Repository {
    constructor(apis) {
        super(apis)
    }

    createUserTest(params = {}) {
        
        const { start,
                end,
                total_test_stems,
                total_correct_choices,
                type,
                score,
                user_id,
                standard_test_id,                
                theme_ids,
                total_correct_quantity,
                total_incorrect_quantity,
                total_unanswered_quantity } = params;        
        return this.$axios            
            .post('api/user-tests', {               
                start: start,
                end: end,
                total_test_stems: total_test_stems,
                total_correct_choices: total_correct_choices,
                type: type,
                score: score,
                user_id: user_id,
                standard_test_id: standard_test_id,                
                theme_ids: theme_ids,
                total_correct_quantity: total_correct_quantity,
                total_incorrect_quantity: total_incorrect_quantity ,
                total_unanswered_quantity: total_unanswered_quantity,
            }).then(({data}) => data)
    }
    
    
    async showUserTest(userTestId){
       return await this.$axios            
            .get('api/user-tests/'+userTestId)
            .then(({ data }) => data)
    }  

    showUserTestbyUserId(userId){
        return  this.$axios            
             .get('api/user-tests/'+userId)
     } 
    

    
    getUserTestStems(userTestId){        
        return this.$axios            
             .get('api/user-tests/'+userTestId+'/stems')
    }
    
    async userTestTheme(userTestId){
        return await this.$axios         
             .get('api/user-tests/'+userTestId+'/themes')
    }

    deleteUserTest(userTestId){
        return this.$axios            
             .delete('api/user-tests/'+userTestId)
    }

    deleteUserTestAll(params={}){  
        const {user_test_ids} = params;           
        return this.$axios            
        .delete('api/user-tests',{ data: {user_test_ids: user_test_ids}})
    }

    getUserTest(subjectId, params={}){        
        return this.$axios            
            .get('api/user-tests/'+subjectId+'/user-tests',{ params
        })             
            
    }     
    
    getQuestionIncorrect(subjectId, params = {}){
        return this.$axios            
             .get('api/user-tests/'+subjectId+'/user-stems',{params: params})
    }

    getStemsCorrectGeneral(){
        return  this.$axios            
             .get('api/user-tests/user-stems/correct')
             .then(({ data }) => data)
    }

    getStemsCorrectGeneralByType(){
        return  this.$axios            
             .get('api/user-tests/user-stems/correct-type')
             .then(({ data }) => data)
    }

    getStemsCorrectThemes(subjectId, userId){
        return  this.$axios            
             .get('api/user-tests/'+subjectId+'/'+userId+'/user-stems-correct-theme')
             .then(({ data }) => data)
    }
    
    getStemsCorrectThemesByType(params = {}){
        return  this.$axios            
             .get('api/user-tests/user-stems-correct/themes-type',{ params: params })
             .then(({ data }) => data)
    }

    
}   
