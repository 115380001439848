import { useState, useEffect, useContext } from "react";

import HeaderEnsayoPage from "../../Organisms/HeaderPage/HeaderEnsayoPage";

import CardTableHistorico from "../../Molecules/CardTableHistorico/CardTableHistorico";

import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { userTestRepo } from "../../../store";

import "./Historical.scss";
import moment from "moment";

const HistoricalEnsayo = () => {
  const { t } = useTranslation();
  const { typeTest, control } = useContext(AuthContext);
  const [dataEnsayo, setDataEnsayo] = useState([]);
  const [loadings, setLoadings] = useState();
  localStorage.removeItem("render");

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
      defaultSortOrder: "descend",
    },
    {
      title: t("date"),
      dataIndex: "date",
      key: "date",      
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new Date(moment(a.date).format("DD/MM/YYYY")) -
        new Date(moment(b.date).format("DD/MM/YYYY")),
    },
    {
      title: typeTest.type === "custom" ? t("note") : t("score"),
      dataIndex: "note",
      key: "note",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.note - b.note,
    },
    {
      title: t("Total"),
      dataIndex: "total",
      key: "total",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: t("correctQuestion"),
      dataIndex: "correct",
      key: "correct",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.correct - b.correct,
    },
    {
      title: t("result"),
      dataIndex: "result",
      key: "result",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.result - b.result,
    },
    {
      title: t("tiempo"),
      dataIndex: "time",
      defaultSortOrder: "descend",
      key: "time",
      sorter: (a, b) => a - b,
    },
  ];

  useEffect(() => {
    onLoad();
    return () => {
      setLoadings();
    };
  }, []);

  const onLoad = async () => {
    var setTime;
    var setTimeSeconds;
    const response = await userTestRepo.getUserTest(control.subjectId);

    for (var i = 0; i < response.data.data.length; i++) {
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "minutes"
        ) %
          60 >
        10
      ) {
        setTime =
          moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "minutes"
          ) % 60;
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "minutes"
        ) %
          60 <
        10
      ) {
        setTime =
          "0" +
          (moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "minutes"
          ) %
            60);
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "seconds"
        ) %
          60 >
        10
      ) {
        setTimeSeconds =
          moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "seconds"
          ) % 60;
      }
      if (
        moment(response.data.data[i].end).diff(
          moment(response.data.data[i].start),
          "seconds"
        ) %
          60 <
        10
      ) {
        setTimeSeconds =
          "0" +
          (moment(response.data.data[i].end).diff(
            moment(response.data.data[i].start),
            "seconds"
          ) %
            60);
      }

     
      if (response.data.data[i].type === "official") {
        setDataEnsayo((dataEnsayo) => [
          ...dataEnsayo,
          {
            key: response.data.data[i].id,
            date: moment(response.data.data[i].start).format("DD/MM/YYYY"),
            note: Math.round(response.data.data[i].score),
            total: response.data.data[i].total_test_stems,
            correct: response.data.data[i].total_correct_choices,
            result: response.data.data[i].score >= control.subjectApprovalGrade
                    ? t("aprobado")
                    : t("reprobado"),
            time:
              moment(response.data.data[i].end).diff(
                moment(response.data.data[i].start),
                "hours"
              ) +
              " : " +
              setTime +
              " : " +
              setTimeSeconds,
          },
        ]);
      }
    }
    setLoadings(false);
  };

  return (
    <div className="max-width">
      
        <HeaderEnsayoPage
          withMenu
          contentButton={t("contentButtonHistorical")}
          routeButton="/ensayo"
          contentTitle={t("contentTitleEnsayo")}
          contentSubtitle={
            t("contentSubtitleHistorical") + " " + control.subjectName
          }
        />
      
      
        <CardTableHistorico
          customClass="mt-20 cols-2"
          columns={columns}
          data={dataEnsayo}
        />
      
    </div>
  );
};

export default HistoricalEnsayo;
