import { useContext, useState, useEffect } from "react";
import { Divider, Modal, Space, Spin } from "antd";
import HeaderEnsayoPage from "../../Organisms/HeaderPage/HeaderEnsayoPage";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { Link, useLocation } from "wouter";
import iconArrowRight from "../../../assets/arrow-right.svg";
import price_header_blue from "../../../assets/price_header_blue.png";
import "./Control.scss";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import {
  userRepo,
  userSubcriptionRepo,
  stantdartTestRepo,
  userTestRepo,
} from "../../../store";
import * as authHelper from "../../../utils/auth";

const ControlsEnsayo = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const {
    subcription,
    user,
    dispatchUser,
    dispatchControl,
    dispatchSubcription,
  } = useContext(AuthContext);
  const [standardTests, setStandardTests] = useState([]);
  const [loadings, setLoadings] = useState();
  const [load, setLoad] = useState(true);
  const [arrayUser, setArrayUser] = useState([]);

  useEffect(() => {
    onLoadUser();
    return () => {
      setLoadings();
    };
  }, []);

  const onLoadUser = () => {
    userRepo.showUser().then(
      ({ data }) => {
        if (subcription === undefined) {
          
          userSubcriptionRepo.getUserSubcription().then(({ data }) => {
            for (var l = 0; l < data.length; l++) {
              for (var n = 0; n < data[l].subjects.length; n++) {
                standardTests.push(data[l].subjects[n].standard_test_id);
              }
            }
            let resultstandardTests = standardTests.filter((item, index) => {
              return standardTests.indexOf(item) === index;
            });
            dispatchSubcription({
              type: types.subcription,
              payload: {
                active: 1,
                standardTest: resultstandardTests,
              },
            });
          });
        }

        if (data.permissions.length !== 0) {
          arrayUser.push({
            name: data.name,
            userId: data.id,
            avatar: data.avatar,
            debug: true,
          });
        }
        if (data.permissions.length === 0) {
          arrayUser.push({
            name: data.name,
            userId: data.id,
            avatar: data.avatar,
            debug: false,
          });
        }
        authHelper.setLocalCurrentUser(
          data.name,
          data.last_name,
          data.avatar,
          data.email
        );
        onLoad(data.id);
      },
      // GET USER ERROR
      (error) => {
        setLoadings(false);
        Modal.error({
          title: t("error"),
          content: error.response.data.message,
        });
      }
    );
  };

  const userSubcription = (standardTestId) => {
    var subjectsDate = [];
    userSubcriptionRepo.getUserSubcriptionDetails().then(
      ({ data }) => {
        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < data[i].subjects.length; j++) {
            for (var k = 0; k < subjectsDate.length; k++) {
              if (subjectsDate[k].subject === data[i].subjects[j].name) {
                var date1 = new Date(data[i].subscription_end);
                var date2 = new Date(subjectsDate[k].dateSubscription);
                if (date1.getTime() > date2.getTime()) {
                  subjectsDate.splice(k, 1, {
                    id: subjectsDate[k].id,
                    subject: subjectsDate[k].subject,
                    dateSubscription: data[i].subscription_end,
                    standardTestId: subjectsDate[k].standardTestId,
                    status: "Active",
                  });
                }
              }
            }
            if (standardTestId === data[i].package.standard_test_id) {
              subjectsDate.push({
                id: data[i].subjects[j].id,
                subject: data[i].subjects[j].name,
                dateSubscription: data[i].subscription_end,
                standardTestId: data[i].package.standard_test_id,
                status: data[i].status,
              });
            }
          }
        }
      },
      (error) => {
        setLoadings(false);
        if (error.response.status == 401) {
          Modal.error({
            title: t("error"),
            content: error,
          });
          setLocation("/login");
        } else {
          Modal.error({
            title: t("error"),
            content: error,
          });
        }
      }
    );

    return subjectsDate;
  };

  const onLoad = async (userId) => {
    if(subcription !== undefined){
    for (var l = 0; l < subcription.standardTest.length; l++) {
      const arraySubjects = userSubcription(subcription.standardTest[l]);
      const countTest = await userTestRepo.showUserTestbyUserId(userId);
      const standardTest = await stantdartTestRepo.findStandartTest(
        subcription.standardTest[l]
      );
      var hash = {};
      var array = arraySubjects.filter(function (current) {
        var exists = !hash[current.id];
        hash[current.id] = true;
        return exists;
      });
      setStandardTests((standardTests) => [
        ...standardTests,
        {
          standardTestId: subcription.standardTest[l],
          standardTestName: standardTest.data.data.name,
          period: standardTest.data.data.period_id,
          subjects: array,
          countTest: countTest.data.data.length,
        },
      ]);
      setLoad(false);
    }

    setLoadings(false);
  }
  };

  const onSendControl = (
    id,
    name,
    approvalGrade,
    standard_test_id,
    period,
    countTest
  ) => {
    dispatchUser({
      type: types.login,
      payload: {
        name: arrayUser[0].name,
        userId: arrayUser[0].userId,
        avatar: arrayUser[0].avatar,
        debug: arrayUser[0].debug,
      },
    });
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: id,
        subjectName: name,
        subjectApprovalGrade: approvalGrade,
        standartTestId: standard_test_id,
        periodId: period,
        countTest: countTest,
      },
    });
    setLocation("/temasEnsayo");
  };

  const onSendHistorial = (
    id,
    name,
    approvalGrade,
    standard_test_id,
    period,
    countTest
  ) => {
    dispatchUser({
      type: types.login,
      payload: {
        name: arrayUser[0].name,
        userId: arrayUser[0].userId,
        avatar: arrayUser[0].avatar,
        debug: arrayUser[0].debug,
      },
    });
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: id,
        subjectName: name,
        subjectApprovalGrade: approvalGrade,
        standartTestId: standard_test_id,
        periodId: period,
        countTest: countTest,
      },
    });
    setLocation("/historico/ensayo");
  };

  const onSendStatistics = (
    id,
    name,
    approvalGrade,
    standard_test_id,
    period,
    countTest
  ) => {
    dispatchUser({
      type: types.login,
      payload: {
        name: arrayUser[0].name,
        userId: arrayUser[0].userId,
        avatar: arrayUser[0].avatar,
        debug: arrayUser[0].debug,
      },
    });
    dispatchControl({
      type: types.control,
      payload: {
        subjectId: id,
        subjectName: name,
        subjectApprovalGrade: approvalGrade,
        standartTestId: standard_test_id,
        periodId: period,
        countTest: countTest,
      },
    });
    setLocation("/estadistica/ensayo");
  };

  const onSendIncrease = () => {
    userSubcriptionRepo.getUserSubcriptionDetails().then(
      ({ data }) => {
        userSubcriptionRepo
          .createUserSubcriptionIncrease({
            id: data[0].id,
          })
          .then((response) => {
            window.open(response.data.data[1], "_blank");
          });
      },
      (error) => {
        Modal.error({
          title: "Error message",
          content: error.response.data.message,
        });
      }
    );
  };

  return (
    <div className="page-controls max-width">
      <HeaderEnsayoPage
        contentButton={t("contentButton")}
        routeButton="/dashboard"
        contentTitle={t("contentTitleE")}
        contentSubtitle={t("contentSubtitleE")}
      />
      {load === true ? (
        <div className="mt-60">
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Spin tip="Loading..." size="large"></Spin>
          </Space>
        </div>
      ) : (
        <div className="mt-30">
          {standardTests.map((name, j) => (
            <div className="mt-20">
              <Subtitle
                h4
                withDivider
                content={name.standardTestName}
                customClass=" c-blue-primary "
              />

              <Divider></Divider>
              <div className="matters-grid mt-20">
                {name.subjects.map((matters, i) => (
                  <div className="price-table-item" key={i + j + 1}>
                    <div className="price-table-header">
                      <img
                        src={price_header_blue}
                        alt={t("alt")}
                        className="table-control-header-image"
                      />
                      <div className="table-header-content-control">
                        <Subtitle
                          h4
                          content={matters.subject}
                          customClass={"c-white"}
                        />
                        <Subtitle h4 content="" customClass={"c-white"} />
                      </div>
                      <div className="price-table-list">
                        <ul>
                          {matters.status === "Active" || matters.status === "Trial" ? (
                            <li className="montserrat-15">
                              <Link
                                href=""
                                onClick={() =>
                                  onSendControl(
                                    matters.id,
                                    matters.subject,
                                    matters.approvalGrade,
                                    matters.standardTestId,
                                    name.period,
                                    name.countTest
                                  )
                                }
                              >
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 c-blue-second">
                                    {t("seesubject")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <li className="montserrat-15">
                              <Link href="/cuenta/editar">
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 bold c-blue-second">
                                    {t("toSubscribe")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          )}
                          {name.countTest !== 0 ? (
                            <li className="montserrat-15">
                              <Link
                                href=""
                                onClick={() =>
                                  onSendHistorial(
                                    matters.id,
                                    matters.subject,
                                    matters.approvalGrade,
                                    matters.standardTestId,
                                    name.period,
                                    name.countTest
                                  )
                                }
                              >
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 c-blue-second">
                                    {t("viewhistory")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <li className="montserrat-15 ">
                              <div className="card-matter-item mt-15">
                                <img src={iconArrowRight} alt="Icon right" />
                                <p className="montserrat-14 ">
                                  {t("viewhistory")}
                                </p>
                              </div>
                            </li>
                          )}
                          {name.countTest !== 0 ? (
                            <li className="montserrat-15">
                              <Link
                                href=""
                                onClick={() =>
                                  onSendStatistics(
                                    matters.id,
                                    matters.subject,
                                    matters.approvalGrade,
                                    matters.standardTestId,
                                    name.period,
                                    name.countTest
                                  )
                                }
                              >
                                <div className="card-matter-item mt-15">
                                  <img src={iconArrowRight} alt="Icon right" />
                                  <p className="montserrat-14 c-blue-second">
                                    {t("viewstatistics")}
                                  </p>
                                </div>
                              </Link>
                            </li>
                          ) : (
                            <li className="montserrat-15">
                              <div className="card-matter-item mt-15">
                                <img src={iconArrowRight} alt="Icon right" />
                                <p className="montserrat-14 ">
                                  {t("viewstatistics")}
                                </p>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ControlsEnsayo;
