import { Button, Modal,Steps } from "antd";
import "./SuccessfulSubscription.scss";
import { useEffect, useContext, useState } from "react";
import Logo from "../../Atoms/Logo/Logo";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { userSubcriptionRepo } from "../../../store";
import moment from "moment";
const { Step } = Steps;

const SuccessfulSubscription = () => {
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const { dispatchSubcription } = useContext(AuthContext);
  const [ daysTrial, setDaysTrial] = useState()

  const steps = [
    {
      title: t("registro"),
      status: "finish",
    },
    {
      title: "E-mail",
      status: "finish",
    },
    {
      title: t("materia"),
      status: "finish",
    },
    {
      title: t("direccion"),
      status: "finish",
    },
    {
      title: t("completed"),
      status: "finish",
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lang"));
    localStorage.setItem("redirect", 1);
    localStorage.setItem("header", 1);        
  },[]);


  const onSend =  () =>{
    var arraySubjects = "";

    userSubcriptionRepo.getUserSubcription().then( ({ data }) => {    
      var standardTests = [];
      var status = "";
      var days = 0;
      var fecha1 = moment(data[0].subscription_start);
      var fecha2 = moment(
        new Date().toISOString().slice(0, 10),
        "YYYY-MM-DD"
      );
      days = fecha1.diff(fecha2, "days");
      setDaysTrial(daysTrial);
      var status = data[0].status;
      for (var n = 0; n < data[0].subjects.length; n++) {
        standardTests.push(data[0].subjects[n].standard_test_id);
        arraySubjects += data[0].subjects[n].name + ",";
      }
      let resultstandardTests = standardTests.filter((item, index) => {
        return standardTests.indexOf(item) === index;
      });
      userSubcriptionRepo.notificationTrial({        
                  name: localStorage.getItem("username"),
                  subject: arraySubjects ,
                  package: data[0].package.name,
                  duration: data[0].trial_duration,
                  subscriptionEnd:moment(data[0].subscription_end).format('L'),
                  email: localStorage.getItem("email"),
          
      });
      dispatchSubcription({
        type: types.subcription,
        payload: {
          active: 1,
          standardTest: resultstandardTests,
          status: status,
          days: days,
        },
      });

      setLocation('/dashboard');
    },
    (error) => {      
      if (error.response.status === 401) {
        Modal.error({
          title: t("error"),
          content: error,
        });
        setLocation("/login");
      } else {
        Modal.error({
          title: t("error"),
          content: error,
        });
      }
      if (error.response.status === 429) {        
        setLocation("/confirmacion");
      } 
    })
  };

  return (
    <div className="payment_container ">
      <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
      <div className="box-white">
        <Steps style={{ marginRight: 120 }} responsive={"600px"}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} status={item.status} />
          ))}
        </Steps>

        <h3 className="mb-10 text-center  pt-30 pl-20 pr-20">{t("msgTrial1")}</h3>
        <p className="mb-10  text-center pr-20">{t("msgTrial2")} {daysTrial} </p>
        <p className="mb-10  text-center ">{t("msgTrial3")}</p>
        <p className="mb-10 text-center">{t("msgTrial4")}</p>
           <div className="text-center mt-20 pb-30">        
          <Button type="primary" onClick={() => onSend()}>
            {t("btncontinue")}
          </Button>
        
        </div>
      </div>
    </div>
  );
};

export default SuccessfulSubscription;
