import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Modal,
  Pagination,
  Space,
  Table,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { SecondsTimer } from "../../Molecules/Timer/SecondsTimer";
import { userTestStemRepo, stemContextRepo } from "../../../store";
import "./EssaysCheck.scss";


const EssaysCheck = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: "#",
      dataIndex: "questionNumber",
      key: "questionNumber",
    },
    {
      title: t("mark"),
      dataIndex: "mark",
      key: "mark",
    },
  ];
  const [, setLocation] = useLocation();
  const { user } = useContext(AuthContext);
  const { control } = useContext(AuthContext);
  const { historical } = useContext(AuthContext);
  const { typeTest } = useContext(AuthContext);
  const [question, setQuestion] = useState([]);
  const [totalStem, setTotalStem] = useState();
  const [answer, setAnswer] = useState([]);
  const [context, setContext] = useState(true);
  const [answerMultiple, setAnswerMultiple] = useState([]);
  const [data, setData] = useState([]);
  const [modalVisibleInfo, setModalVisibleInfo] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [explanition, setExplanition] = useState(false);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState([]); //radio group
  //Variable encargada de la paginación
  const [current, setCurrent] = useState(1);
  const [loadings, setLoadings] = useState();

  let setSize = false;
  const key = "updatable";

  React.useEffect(() => {
    window.onpopstate = (e) => {
      if (localStorage.getItem("header") === "0") {
        onSend();
        localStorage.setItem("header", 1);
      }
    };
    window.onbeforeunload = (e) => {
      if (localStorage.getItem("header") === "0") {
        onSend();
        localStorage.setItem("header", 1);
      }
    };
  });

  useEffect(() => {
    onload();
    return () => {
      setLoadings();
    };
  }, []);

  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, node.current]);
  };

  const onload = async () => {
    setModalVisibleInfo(true);
    const response = await userTestStemRepo.getUserTestStem(
      historical.userTest
    );
    const responseContext = await stemContextRepo.getStemContext();

    for (var j = 0; j < response.data.length; j++) {
      setValue((value) => [...value, { id: null, value: null }]);
      setData((data) => [
        ...data,
        {
          key: j + 1,
          questionNumber: j + 1,
          response: response.data[j].is_correct ? t("correct") : t("incorrect"),
        },
      ]);
    }
    setTotalStem(response.data.length);

    for (var i = response.data.length - 1; i >= 0; i--) {
      var stemChoice = "";
      const ans = [];
      if (response.data[i].stem["is_multi_choice"] === 1) {
        for (var s = 0; s < response.data.length; s++) {
          ans.splice(
            ans.length,
            0,
            "<strong>" +
              s +
              "</strong>" +
              response.data[s].body +
              (response.data[s].length === 0
                ? t("unanswered")
                : response.data[s].is_correct === 1
                ? " <strong>" + t("correct") + "</strong>"
                : " <strong>" + t("incorrect") + "</strong><br>")
          );
        }
      } else {
        if (response.data[i].stem_choices.length === 0) {
          stemChoice = "<strong>" + t("unanswered") + "</strong>";
        }
        if (response.data[i].stem_choices.length !== 0) {
          stemChoice =
            response.data[i].stem_choices[0].body +
            "....." +
            (response.data[i].is_correct === 1
              ? " <strong>" + t("correct") + "</strong>"
              : " <strong>" + t("incorrect") + "</strong><br>");
        }
      }

      if (response.data[i].stem["stem_context_id"] !== null) {
        const context = responseContext.data.data.filter(
          (item) => item.id === response.data[i].stem["stem_context_id"]
        );
        setQuestion((question) => [
          ...question,
          {
            id: response.data[i].stem_id,
            statement: response.data[i].stem["statement"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            solution_body: response.data[i].stem["solution_body"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            theme_id: response.data[i].stem["period_theme_id"],
            stem_context_id: response.data[i].stem["stem_context_id"],
            answer:
              response.data[i].stem["is_multi_choice"] === 1 ? ans : stemChoice,
            context: context[0].body.replace(/<a /g, '<a target="blank" '),
            correct: response.data[i].is_correct,
            marked: response.data[i].is_marked,
            multioption: response.data[i].stem["is_multi_choice"],
            url_source:
              response.data[i].stem["url_source"] !== null
                ? response.data[i].stem["url_source"]
                : null,
          },
        ]);
      } else {
        setQuestion((question) => [
          ...question,
          {
            id: response.data[i].stem_id,
            statement: response.data[i].stem["statement"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            solution_body: response.data[i].stem["solution_body"].replace(
              /<a /g,
              '<a target="blank" '
            ),
            theme_id: response.data[i].stem["period_theme_id"],
            stem_context_id: response.data[i].stem["stem_context_id"],
            answer:
              response.data[i].stem["is_multi_choice"] === 1 ? ans : stemChoice,
            context: null,
            correct: response.data[i].is_correct,
            marked: response.data[i].is_marked,
            multioption: response.data[i].stem["is_multi_choice"],
            url_source:
              response.data[i].stem["url_source"] !== null
                ? response.data[i].stem["url_source"]
                : null,
          },
        ]);
      } //if contetx
    } //for
    setModalVisibleInfo(false);
    setLoadings(false);
  }; //fin de la funcion onload

  //método que add/delete la pregunta marcada
  const replaceItem = (indexToReplace, newItem) => {
    const updatedItems = [...data];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setData(updatedItems);
  };
  //Método de las respuestas simples
  const onChange = (e) => {
    replaceAnswer(e.target.name - 1, {
      id: e.target.name,
      value: e.target.value,
    });
    replaceSelect(e.target.name - 1, e.target.value);
    
  };
  //método de respuestas multiples
  const onChangeMulti = (e, current) => {
    
    replaceAnswer(current - 1, { id: current, value: e });
    replaceSelect(current - 1, e);
  };
  //metodo que guarda las respuestas seleccionadas
  const replaceSelect = (indexToReplace, newItem) => {
    const updatedItems = [...value];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setValue(updatedItems);
  };
  //método que guarda las respuestas simples
  const replaceAnswer = (indexToReplace, newItem) => {
    const updatedItems = [...answer];
    updatedItems.splice(indexToReplace, 1, newItem);
    return setAnswer(updatedItems);
  };

  const onChangeExplanition = (e) => {
    setExplanition(e.target.checked);
  };

  const onChangeContext = (e) => {
    setContext(e.target.checked);
  };

  const handleModal = (modalVisible) => {
    setModalVisible(modalVisible);
  };

  const sizeChangeLogic = (current, size) => {
    setSize = true;
    setCurrent(1);
  };

  const onChangeLogic = (current) => {
    setCurrent(current);
  };

  const onSend = () => {
    if(typeTest.type === 'custom'){
     setLocation("/historico");
    }
    if(typeTest.type === 'official'){
      setLocation("/historico/ensayo");
      }
    
  };

  return (
    <div className="max-width unselectable ">
      <div className="main-check-grid " ref={node}>
        <Card
          className="mt-20 cols-1"
          title={control.subjectName}
          bordered={false}
        >
          {typeof question != "undefined" ? (
            question
              .filter((item, i) => i + 1 === current)
              .map((item, i) => (
                <Card key={`key-essay-check${current}`}>
                  <div className="pxy-10">
                    <div className="pl-10">
                      <Button type="primary">
                        {t("titleQuestion")} {current}
                      </Button>
                      <div className="container-buttons-register" ref={node}>
                        {item.context !== null ? (
                          <div>
                            <div className="row">
                              <div className="col-80">
                                <p className="c-black mt-10">
                                  {item.stem_context_title}
                                </p>
                              </div>
                              <div className="col-20">
                                <Form.Item>
                                  <Checkbox
                                    name="context"
                                    onChange={onChangeContext}
                                    defaultChecked={true}
                                  >
                                    {t("showContext")}
                                  </Checkbox>
                                </Form.Item>
                              </div>
                            </div>
                            {context === true ? (
                              <div
                                className="main-ensayos-context mt-10"
                                dangerouslySetInnerHTML={{
                                  __html: item.context,
                                }}
                              />
                            ) : null}
                            <Divider />
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="mt-20"
                        dangerouslySetInnerHTML={{ __html: item.statement }}
                      />
                      <p className="main-ensayos-button c-black">
                        {item.correct ? "Correcta" : "Incorrecta"}
                      </p>

                      {item.multioption === 0 ? (
                        <Space direction="vertical">
                          <div
                            className="mt-20 stem"
                            style={{   whiteSpace: "initial", textAlign:"justify"}}
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          />
                        </Space>
                      ) : (
                        <Space direction="vertical">
                          <div
                            className="mt-20 stem"
                            style={{   whiteSpace: "initial", textAlign:"justify"}}
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          />
                        </Space>
                      )}
                      <Divider></Divider>
                      <Form.Item noStyle>
                        <Checkbox
                          name="explanation"
                          onChange={onChangeExplanition}
                        >
                          {t("explanation")}
                        </Checkbox>
                        {explanition === true ? (
                          <div
                            className="mt-20 main-explanition"
                            dangerouslySetInnerHTML={{
                              __html: item.solution_body.replace(
                                /<a /g,
                                '<a target="blank" '
                              ),
                            }}
                          />
                        ) : null}
                      </Form.Item>

                      {item.url_source !== null ? (
                        <div>
                          <Divider></Divider>
                          <div className="mt-5">
                            <a className="montserrat-9">
                              Información tomada de{" "}
                              <button
                                className="url montserrat-9"
                                onClick={() =>
                                  window.open(item.url_source, "_blank")
                                }
                              >
                                Aqui
                              </button>
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Card>
              ))
          ) : (
            <h1>Loading..</h1>
          )}

          <div className="pxy-30">
            <div className="main-ensayos-pagination-questions mt-15 mb-15">
              <Pagination
                current={current}
                onShowSizeChange={sizeChangeLogic}
                onChange={onChangeLogic}
                showSizeChanger={false}
                total={data.length * 10}
              />
            </div>

            <div className="main-ensayos-buttons-questions">
              

              <Button type="primary" icon={<RightOutlined />} onClick={onSend}>
                {t("btnfinalizar")}
              </Button>
            </div>
          </div>
        </Card>

        <Card className="mt-20 cols-2" title={t("status")} bordered={false}>
          <div className="pxy-30 ">
            <Table
              size="small"
              rowClassName={(a, b) => b % 2 === 0 && "bg-light"}
              scroll={{ x: 300 }}
              pagination={true}
              columns={columns}
              dataSource={data}
            />
          </div>
        </Card>
      </div>
      <Modal
        title={t("msginfo")}
        style={{ top: 70 }}
        open={modalVisibleInfo}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <h3>{t("paragraph1")}</h3>
        <br></br>
        <p>{t("paragraph2")}</p>
        <p>{t("paragraph3")}</p>
        <p>{t("paragraph4")}</p>
        <p>{t("paragraph5")}</p>
        <br></br>
        <h3>{t("paragraph6")}</h3>
        <br></br>
        <p>{t("paragraph7")}</p>
        <p>{t("paragraph8")}</p>
        <p>{t("paragraph9")}</p>
        <br></br>
        <SecondsTimer />
      </Modal>
    </div>
  );
};

export default EssaysCheck;
