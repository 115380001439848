import createAddressRepository from '../services/api/addressRepository'
import createAuthRepository from '../services/api/authenticationRepository'
import createCityRepository from '../services/api/cityRepository'
import createGradeRepository from '../services/api/gradeRepository'
import createFeedbackBuilderRepository from '../services/api/feedbackbuilderRepository'
import createPackageRepository from '../services/api/packageRepository'
import createPeriodRepository from '../services/api/periodRepository'
import createStandardTestRepository from '../services/api/standardTestRepository'
import createStateRepository from '../services/api/stateRepository'
import createStemRepository from '../services/api/stemRepository'
import createStemContextRepository from '../services/api/stemContextRepository'
import createSubjectRepository from '../services/api/subjectRepository'
import createThemeRepository from '../services/api/themeRepository'
import createTownRepository from '../services/api/townRepository'
import createUserRepository from '../services/api/userRepository'
import createUserSubcriptionRepository from '../services/api/userSubcriptionRepository'
import createUserSubjectStatsRepository from '../services/api/userSubjectStatsRepository'
import createUserTestRepository from '../services/api/userTestRepository'
import createUserTestStemRepository from '../services/api/userTestStemRepository'
import createVerifyEmailRepository from '../services/api/verifyEmailRepository'


import { axiosClient } from '../services/axios/index';
import { verifyEmailClient } from '../services/verifyEmail/index';

const context = {
    axios: axiosClient,
    verifyEmail: verifyEmailClient 
}

export const authRepo = Object.freeze(new createAuthRepository(context))
export const userRepo = Object.freeze(new createUserRepository(context))
export const addressRepo = Object.freeze(new createAddressRepository(context))
export const cityRepo = Object.freeze(new  createCityRepository(context))
export const gradeRepo = Object.freeze(new  createGradeRepository(context))
export const feedbackRepo = Object.freeze(new  createFeedbackBuilderRepository(context))
export const packageRepo = Object.freeze(new  createPackageRepository(context))
export const periodRepo = Object.freeze(new  createPeriodRepository(context))
export const stantdartTestRepo = Object.freeze(new  createStandardTestRepository(context))
export const stateRepo = Object.freeze(new  createStateRepository(context))
export const stemRepo = Object.freeze(new  createStemRepository(context))
export const stemContextRepo = Object.freeze(new  createStemContextRepository(context))
export const subjectRepo = Object.freeze(new  createSubjectRepository(context))
export const themeRepo = Object.freeze(new  createThemeRepository(context))
export const townRepo = Object.freeze(new  createTownRepository(context))
export const userSubcriptionRepo = Object.freeze(new  createUserSubcriptionRepository(context))
export const userSubjectStatsRepo = Object.freeze(new  createUserSubjectStatsRepository(context))
export const userTestRepo = Object.freeze(new createUserTestRepository(context))
export const userTestStemRepo = Object.freeze(new createUserTestStemRepository(context))
export const verifyEmailRepo = Object.freeze(new createVerifyEmailRepository(context))
