import { useContext } from "react";
import "./BannerDashboard.scss";
import image_banner from "../../../assets/image_banner.png";
import { Button } from "antd";
import { useLocation } from "wouter";
import Title from "../../Atoms/Title/Title";
import Subtitle from "../../Atoms/Subtitle/Subtitle";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { useTranslation } from "react-i18next";

const BannerDashboard = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { dispatchType } = useContext(AuthContext);

  const handleControl = () => {
    dispatchType({
      type: types.typeTest,
      payload: {
        type: "custom",
        value: 1,
      },
    });
    setLocation("/control");
  };

  const handleEnsayo = () => {
    dispatchType({
      type: types.typeTest,
      payload: {
        type: "official",
        value: 1,
      },
    });

    setLocation("/ensayo");
  };

  return (
    <div className="main-banner">
      <div className="main-banner_image">
        <img src={image_banner} alt="Banner" />
      </div>
      <div className="main-banner_content">
        <Title content={t("titleIkenga")} customClass="mb-15 c-blue-primary" />
        <Subtitle
          content={t("subTitleIkenga")}
          customClass="mb-15 c-green-primary sm-bold"
        />
        <div className="main-banner_home_buttons">
          <Button type="primary" onClick={handleControl}>
            {t("control")}
          </Button>
          <Button type="primary" onClick={handleEnsayo}>
            {" "}
            {t("ensayo")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BannerDashboard;
