export const AZURE = {
    storageUrl: process.env.REACT_APP_AZURE_STORAGE_URL,
    storageContainer: process.env.REACT_APP_AZURE_STORAGE_CONTAINER,
    getUrl: () => {
        return AZURE.storageUrl + AZURE.storageContainer
    }
}

export const APP = {
    apiUrl: process.env.REACT_APP_API_URL
}

export const APP_IKENGA = {
    ikengaUrl: process.env.REACT_APP_IKENGA_URL
}

export const GOOGLE_ANALYTICS = {
    googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS_ID
}


