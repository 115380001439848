import { useContext } from "react";
import { Avatar } from "antd";
import right_icon from "../../../assets/icon-right.svg";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { GlobalOutlined } from "@ant-design/icons";
import "./MenuUser.scss";
import { APP_IKENGA } from "../../../Config/config";
import AzureAvatar from "../../Atoms/AzureAvatar/AzureAvatar";

const MenuUser = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { dispatchType } = useContext(AuthContext);
  const { i18n } = useTranslation();

  const  changeLanguage = (lang) => {
       i18n.changeLanguage(lang);
  }

  const handleControl = () => {
    dispatchType({
      type: types.typeTest,
      payload: {
        type: "custom",
      },
    });

    setLocation("/control");
  };

  const handleEnsayo = () => {
    dispatchType({
      type: types.typeTest,
      payload: {
        type: "official",
      },
    });

    setLocation("/ensayo");
  };

  // Aqui lógica para el cierre de sesion
  const handleCloseSession = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("username");
    localStorage.removeItem("avatar");
    localStorage.removeItem("redirect");
    localStorage.removeItem("header");
    setLocation("/login");
    window.open(APP_IKENGA.ikengaUrl, "_self");
  };

  const goToRoute = (route) => {
    setLocation(route);
  };

  return (
    <div className={`header-toolip user`}>
      <div className="header-toolip_user">
        {localStorage.getItem("avatar") === "null" ? (
          <Avatar
            style={{
              backgroundColor: "#0C4393",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {localStorage.getItem("username").slice(0, 1).toUpperCase()}
          </Avatar>
        ) : (
          <AzureAvatar value={localStorage.getItem("avatar")} size="middle" />
        )}
        <div className="mb-5">
          <p className="montserrat-15 c-black mb-5">
            {localStorage.getItem("username")}
          </p>
        </div>
      </div>
      <div className="toolip-divider" />
      <div className="header-toolip_links">
        <div
          className="header-toolip_link mb-10"
          onClick={() => goToRoute("/dashboard")}
        >
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black ">Dashboard</p>
        </div>
        <div className="header-toolip_link mb-10" onClick={handleControl}>
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("control")}</p>
        </div>
        <div className="header-toolip_link mb-10" onClick={handleEnsayo}>
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("ensayo")}</p>
        </div>
        <div
          className="header-toolip_link mb-10"
          onClick={() => goToRoute("/cuenta/editar")}
        >
          <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("profile")}</p>
        </div>
         <div
          className="header-toolip_link mb-10"
          onClick={() => goToRoute("/agregar/subscripcion")}
        >
         <img src={right_icon} alt="icon right" className="mr-5" />
          <p className="montserrat-15 c-black">{t("renewalSubscription")}</p>
        </div>
      </div>
      
      <div>
        {localStorage.getItem("lang") === "en" ? (
          <button className="menu-button" onClick={() => changeLanguage("es")}>
            {" "}
            <GlobalOutlined style={{ width: "20px" }} />
            es
          </button>
        ) : (
          <button className="main-button" onClick={() => changeLanguage("en")}>
            {" "}
            <GlobalOutlined style={{ width: "20px" }} />
            en
          </button>
        )}
      </div>
      <div className="toolip-divider" />
      
      <div className="header-toolip_sesion">
        <p
          className="montserrat-15 c-blue-primary"
          onClick={handleCloseSession}
        >
          {t("cerrar")}
        </p>
      </div>
    </div>
  );
};

export default MenuUser;
