import Repository from './repository.js'
export default class PackageRepository extends Repository {

    constructor(apis) {
        super(apis)
    }
  
    getAll(){
        return this.$axios            
             .get('api/packages')             
    }  

    getPackage(packageId){
        return this.$axios            
             .get('api/packages/'+packageId)
             
    }

    getMaxSubsjects(standardTestId){
        return this.$axios            
             .get('api/packages/' + standardTestId + '/max-subjects')
             
    }

    getSubjects(packageId){
        return this.$axios            
             .get('api/packages/' + packageId + '/subjects')
             
    }

    getUserSubscription(standardTestId){
        return this.$axios            
             .get('api/packages/' + standardTestId + '/user-subscriptions')
             
    }
}


    
