import { useContext} from "react"
import ActiveLinkD from "../../Atoms/ActiveLinkD/ActiveLinkD";
import "./MenuForHeaderPage.scss";
import { useTranslation } from "react-i18next"
import { AuthContext } from "../../../auth/AuthContext"

const MenuForHeaderEnsayoPage = () => {
  const { typeTest, subcription,control } = useContext(AuthContext)
  const { t } = useTranslation()
  return (
    <nav className="header-page-main-menu">
      <ul className="header-page-main-menu_list">
        {subcription.active === 1 ?           
          <ActiveLinkD href="/ensayo" type={typeTest.type}>{t('proof')}</ActiveLinkD>
          : 
          null
        } 
        
        <ActiveLinkD href="/historico/ensayo" type={''}>{t('historical')}</ActiveLinkD>
                
       
        <ActiveLinkD href="/estadistica/ensayo" type={''}>{t('statistics')}</ActiveLinkD>      
      </ul>
    </nav>
  );
};

export default MenuForHeaderEnsayoPage;
