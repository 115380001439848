import { React, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import { Column } from "@ant-design/charts";
import { Card ,Modal} from "antd";
import { useLocation } from "wouter";
import { useTranslation } from "react-i18next";
import { userTestRepo } from "../../../store";

const ChartsColumn = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [, setLocation] = useLocation();
  const [dataList, setDataList] = useState([]);
  const [cleanup, setCleanup] = useState();

  const asyncFetch = () => {
    userTestRepo
      .getStemsCorrectGeneralByType()
      .then(({ data }) => {
        for (var i = 0; i < data.length; i++) {
          setDataList((dataList) => [
            ...dataList,
            {
              name:
                data[i].type === "custom"
                  ? t("modalityControl")
                  : t("modalityEnsayo"),
              月份: data[i].name,
              月均降雨量: Math.round(data[i].sumCorrect),
            },
          ]);
        }
      },(error) => {          
        if (error.response.status == 401) {
          Modal.error({
            title: t("error"),
            content: error,
          });
          setLocation("/login");
        } else {
          Modal.error({
            title: t("error"),
            content: error,
          });
        }
      });

    setCleanup(false);
  };

  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
    };
  }, []);

  var config = {
    data: dataList,
    isGroup: true,
    xField: "月份",
    yField: "月均降雨量",
    seriesField: "name",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };

  return (
    <Card title={t("titleChartColumn")} bordered={false}>
      <Column className="pxy-10" {...config} />
    </Card>
  );
};

export default ChartsColumn;
