import { useContext } from "react";
import HeaderPage from "../../Organisms/HeaderPage/HeaderPage";
import CardTheme from "../../Organisms/CardTheme/CardTheme";
import { AuthContext } from "../../../auth/AuthContext";
import { useTranslation } from "react-i18next";

const Themes = () => {
  const { t } = useTranslation();
  const { control } = useContext(AuthContext);
  return (
    <div className="max-width">
      
        <HeaderPage
          withMenu
          contentButton={t("contentButtonTheme")}
          routeButton="/control"
          contentTitle={t("contentTitleTheme")}
          contentSubtitle={t("contentSubtitleTheme")+' '+control.subjectName}
        />
      

       <CardTheme /> 
    </div>
  );
};

export default Themes;
