import { useContext } from "react";
import HeaderEnsayoPage from "../../Organisms/HeaderPage/HeaderEnsayoPage";
import CardThemeEnsayo from "../../Organisms/CardThemeEnsayo/CardThemeEnsayo";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../auth/AuthContext";

const ThemesEnsayo = () => {
  const { t } = useTranslation();
  const { control } = useContext(AuthContext);
  return (
    <div className="max-width">
     
        <HeaderEnsayoPage
          withMenu
          contentButton={t("contentButtonTheme")}
          routeButton="/ensayo"
          contentTitle={t("contentTitleThemeE")}
          contentSubtitle={t("contentSubtitleThemeE")+' '+control.subjectName}
        />
      

      <CardThemeEnsayo />
    </div>
  );
};

export default ThemesEnsayo;
