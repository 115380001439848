import Repository from './repository.js'
export default class UserSubjectStatsRepository extends Repository {
    constructor(apis) {
        super(apis)
    }
    
    getUserSubjectStats(){
        return this.$axios            
             .get('api/user-subject-stats/my-percentages')
             .then(({ data }) => data)
             
    }

    getGaussStats(){
        return this.$axios            
             .get('api/user-subject-stats/my-percentages-gauss')
             .then(({ data }) => data)
             
    }
    
    getUserSubjectStatsAverageList(){
        return this.$axios            
             .get('api/user-subject-stats/average-list')
             .then(({ data }) => data)
    }
    
    getUserSubjectStatsColumn(userId, subjectId, type){
       return this.$axios            
            .get('api/user-subject-stats'+ userId +'/'+ subjectId +'/'+ type)
    } 
   
    getUserThemeStatsColumn( params = {}){        
        return this.$axios            
            .get('api/user-subject-stats/themes-stats',{ params: params })
            .then(({ data }) => data)
    } 

    getThemeStatsByGeneral( params = {}){       
        return this.$axios            
            .get('api/user-subject-stats/themes-stats-general',{ params: params })
            .then(({ data }) => data)
    } 

}