import ActiveLinkD from "../../Atoms/ActiveLinkD/ActiveLinkD";
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";
import "./MainMenu.scss";

const MainMenu = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const  changeLanguage = (lang) => {    
    localStorage.setItem('lang',lang);
    i18n.changeLanguage(lang);
  }

  return (
    <nav className="main-menu">
      <ul className="main-menu_list">
        
        <ActiveLinkD href="/dashboard" type={""}>
          Dashboard
        </ActiveLinkD>
        <ActiveLinkD href="/control" type={"custom"}>
          {t("control")}
        </ActiveLinkD>
        <ActiveLinkD href="/ensayo" type={"official"}>
          {t("ensayo")}
        </ActiveLinkD>       
        <div >
        {localStorage.getItem('lang') === "en" ?
          <button  className="main-button" onClick={() =>changeLanguage('es')}>
            {" "}
            <GlobalOutlined style={{ width: "20px" }} />
            es
          </button>
         
          :
          <button className="main-button" onClick={() =>changeLanguage('en')}>
            {" "}
            <GlobalOutlined style={{ width: "20px" }} />
            en
          </button>
           }
        </div>      
      </ul>
    </nav>
  );
};

export default MainMenu;
