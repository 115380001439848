import React from "react";
import { HourglassOutlined } from "@ant-design/icons";
import { useStopwatch } from "react-timer-hook";


 

export const  SecondsTimer = () => {
  const { pause, seconds } =  useStopwatch({
     autoStart: true 
  });

  return (
    <div style={{textAlign: "right"}}>
      <HourglassOutlined /> Su prueba iniciará en  {seconds} ...
    </div>
  );
};
