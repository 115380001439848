import { Checkbox, Form, Input, Button, Modal, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "../../../customHooks/useForm";
import { Link, useLocation } from "wouter";
import { PoweroffOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Password from "antd/lib/input/Password";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { APP_IKENGA } from "../../../Config/config";
import * as authHelper from "../../../utils/auth";
import { userRepo } from "../../../store";
import { useRut } from "react-rut-formatter";
import ModalAntdTC from "../../Atoms/Modal/ModalAntdTC";

const RegisterStep1 = ({ setStep1Data, step1Data }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  var id = window.location.href.split("/");

  const [, setLocation] = useLocation();

  const [loadings, setLoadings] = useState();

  const [phone, setPhone] = useState();

  const [state, setState] = useState(false);

  const { rut, updateRut, isValid } = useRut();

  const [formValues, handleInputChange] = useForm({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const { firstName, lastName, password, email } = formValues;

  useEffect(() => {
    if (id[5] !== undefined) {
      if(localStorage.getItem("banner") === null || localStorage.getItem("banner") === 'null' ){
        window.location.reload();
      }
      i18n.changeLanguage(id[5]);
      localStorage.setItem("banner", id[6]);
      localStorage.setItem("lang", id[5]);
      localStorage.setItem("package", id[4]);      
    }else{
      i18n.changeLanguage(localStorage.getItem("lang")); 
      
    }

    return () => {
      setLoadings();
    };
  }, []);

  const onPhone = (phone) => {
    setPhone(phone);
  };

  const onChange = (state) => {
    setState(state);
  };

  const onSend = () => {
     
    setLoadings(true);
    var taxId = rut.raw;
    if (isValid === true && firstName !== null && lastName !== null) {
      
      userRepo
        .createUser({
          name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          phone: phone,
          tax_id: taxId,
          timezone: "Buenos Aires",
          language: "ES",
          terms_and_conditions: state === true ? "1" : "0",
        })
        .then(
          (data) => {
            //id del usuario al que se le va a asociar la dirección
            setLoadings(false);
            Modal.success({
              title: t("info"),
              content: data.message,
            });
          },
          (error) => {
            setLoadings(false);
            if (error.response.status === 401) {
              Modal.error({
                title: t("error"),
                content: error
              });
              authHelper.handleCloseSession();
              setLocation("/login");
            }            
            if (error.response.status === 422) {
              Modal.error({
                title: t("error"),
                content: t("msgRequired"),
              });
            }
            
          }
        );
    } else {
      setLoadings(false);
      Modal.success({
        title: t("info"),
        content: "Rut no válido",
      });
    }
  
  };

  const finishStep1 = (e) => {
    setStep1Data(e); // Guardo los datos del primer formulario en un estado
  };

  return (
    <div>
      <Form
        onFinish={finishStep1}
        initialValues={{
          firstName: step1Data ? step1Data.firstName : null,
          lastName: step1Data ? step1Data.lastName : null,
          rut: step1Data ? step1Data.Rut : null,
          phone: step1Data ? step1Data.phone : null,
          email: step1Data ? step1Data.email : null,
          password: step1Data ? step1Data.password : null,
          confirm: step1Data ? step1Data.confirm : null,
        }}
      >
        <h1 className="mb-15 mt-15 text-center">{t("contentRegister")}</h1>

        <Form.Item
          label={t("firstName")}
          name="firstName"
          rules={[
            { required: true, message: t("msgfirstName"),
              pattern: "^[a-zA-ZáéíóúÁÉÍÓÚñÑ]*$", message: t("msgcharacters")
             
            }
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input
            name="firstName"
            value={firstName}
            onChange={handleInputChange}
            placeholder={t("firstName")}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label={t("lastName")}
          name="lastName"
          rules={[
            { required: true, message: t("msglastName") ,
              pattern: "^[a-zA-ZáéíóúÁÉÍÓÚñÑ]*$", message: t("msgcharacters")
            }
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input
            name="lastName"
            value={lastName}
            onChange={handleInputChange}
            placeholder={t("lastName")}
            size="large"
          />
        </Form.Item>

        <Form.Item
          label={
            <q >
              <span style={{ fontSize: "14px", color: "#030E1F" }}>Rut</span>
            </q>
          }
          name="Rut"
        >
          <Input
            name="rut"
            value={rut.formatted}
            onChange={(e) => updateRut(e.target.value)}
            placeholder="XX.XXX.XXX -Y"
            maxLength={12}
            size="large"
          />
          {isValid && (
            <span style={{ fontSize: "9px", color: "#52c41a" }}>
              RUT Válido
            </span>
          )}
        </Form.Item>

        <Form.Item
          label={t("phone")}
          name="phone"
          rules={[{ required: true, message: t("msgphone") }]}
        >
          <PhoneInput
            country={"cl"}
            masks={{ cl: ".-....-...." }}
            placeholder="+56 X-XXXX-XXXX"
            value={phone}
            onChange={onPhone}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: t("msgemail") },
            { type: "email", message: t("msgemailvalid") },
          ]}
          normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input
            name="email"
            value={email}
            onChange={handleInputChange}
            placeholder="nombre@dominio.com"
            autoComplete="new-email"
            size="large"
          />
        </Form.Item>

        <Form.Item
          label={
            <p className="prueba" style={{ fontSize: "15px" }}>
              <span style={{ fontSize: "14px", color: "#030E1F" }}>
                {t("password")}
              </span>
              <Tooltip
                color="#ff4d4f"
                placement="top"
                title={t("msgpassword1")}
              >
                <InfoCircleOutlined /> :{" "}
              </Tooltip>
            </p>
          }
          name="password"
          colon={false}
          hasFeedback
          rules={[
            { required: true, message: t("msgpassword") },
            {
              pattern:
                "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$",
              message: t("msgpassword2")               
            },
            {
              type: 'string',
              min: 8,
              message: t("msgpasswordLenght")
            }
          ]}
        >
          <Input.Password
            name="password"
            value={Password}
            onChange={handleInputChange}
            autoComplete="new-password"
            placeholder={t("password")}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={t("confirmPassword")}
          dependencies={["password"]}
          hasFeedback
          rules={[
            { required: true, message: t("msgconfirm") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("coincide")));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t("repeat")} size="large" />
        </Form.Item>
        {state === false ? (
          <Tooltip color="#ff4d4f" placement="left" title={t("toolTipTC")}>
            <Form.Item>
              <Checkbox defaultChecked={false} disabled>
                <ModalAntdTC onChange={onChange} />
              </Checkbox>
            </Form.Item>
          </Tooltip>
        ) : (
          <Checkbox checked={true}>
            <ModalAntdTC onChange={onChange} />
          </Checkbox>
        )}

        <div className="container-buttons-register">
          <a href={APP_IKENGA.ikengaUrl} rel="noopener noreferrer">
            <Button>{t("btnback")}</Button>
          </a>
          {state === false ? (
            <button className="gray">
              <PoweroffOutlined />
              {t("btnconfirm")}
            </button>
          ) : (
            <Button
              type="primary"
              className="login-form-button"
              htmlType="submit"
              onClick={onSend}
              icon={<PoweroffOutlined />}
              loading={loadings}
            >
              {t("btnconfirm")}
            </Button>
          )}
        </div>

        <p className="text-center mb-10 mt-20 ">
          {t("msgLogin")}
          <Link href="/login">
            <span className="link">{t("login")}</span>
          </Link>
        </p>
      </Form>
    </div>
  );
};

export default RegisterStep1;
