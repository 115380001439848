import { useContext, useEffect, useState } from "react";
import "./Login.scss";
import { Form, Input, Button, Modal } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import Logo from "../../Atoms/Logo/Logo";
import { Link, useLocation } from "wouter";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { useTranslation } from "react-i18next";
import {
  authRepo,
  userRepo,
  userSubcriptionRepo,
  verifyEmailRepo,
} from "../../../store/index";
import { APP_IKENGA } from "../../../Config/config";
import * as authHelper from "../../../utils/auth";
import moment from "moment";

const Login = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const [loadings, setLoadings] = useState();
  const { dispatchUser, dispatchSubcription } = useContext(AuthContext);
  const paquete = localStorage.getItem("package");
  var id = window.location.href.split("/");

  useEffect(() => {
    if (id[4] !== undefined) {
      if (
        localStorage.getItem("banner") === null ||
        localStorage.getItem("banner") === "null"
      ) {
        window.location.reload();
      }
      i18n.changeLanguage(id[4]);
      localStorage.setItem("lang", id[4]);
      localStorage.setItem("banner", id[5]);
    } else {
      i18n.changeLanguage(localStorage.getItem("lang"));
    }

    localStorage.removeItem("matters");
    localStorage.removeItem("active");
    localStorage.removeItem("firstAddress");
    localStorage.removeItem("secondAddress");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("town");
    localStorage.removeItem("postalCode");
    localStorage.removeItem("message");

    return () => {
      setLoadings();
    };
  }, []);

   const onFinish = (values) => {
    var debug;
    setLoadings(true);
    authRepo.login(values).then(
      () => {
        userRepo.showUser().then(
          ({ data }) => {
            if (data.permissions.length !== 0) {
              debug = true;
            }
            if (data.permissions.length === 0) {
              debug = false;
            }
            authHelper.setLocalCurrentUser(
              data.name,
              data.last_name,
              data.avatar,
              data.email
            );
            dispatchUser({
              type: types.login,
              payload: {
                name: data.name,
                userId: data.id,
                avatar: data.avatar,
                debug: debug,
              },
            });
            //  setLocation("/dashboard");
            if (data.email_verified_at === null) {
              onVerifyEmail(data.email);
            }
            if (data.email_verified_at !== null) {
              onSupcription();
            }
          },
          // GET USER ERROR
          (error) => {
            setLoadings(false);
            Modal.error({
              title: t("error"),
              content: error.response.data.message,
            });
          }
        );
      },
      // LOGIN ERROR
      (error) => {
        setLoadings(false);
        if (error.response) {
          Modal.error({
            title: t("error"),
            content: error.response.data.message.email,
          });
        }
      }
    );
  };

  const onVerifyEmail = (email) => {
    verifyEmailRepo.resendVerifyEmail(email).then(({ data }) => {
      localStorage.setItem("redirect", 0);
      Modal.success({
        title: t("info"),
        content: t("checkEmail"),
      });
      setLoadings(false);
    });
  };

  const onSupcription = () => {
    userSubcriptionRepo.getUserSubcription().then(({ data }) => {
      if (data.length === 0) {
        localStorage.setItem("redirect", 0);
        localStorage.setItem("header", 0);
        dispatchUser({
          type: types.header,
          payload: {
            header: 1,
          },
        });
        dispatchSubcription({
          type: types.subcription,
          payload: {
            active: 1,
            standardTest: '',
            status: '',
            days: '',
            link: '',
          },
        });
        if (paquete !== null) {
          if (localStorage.getItem("render") === null) {
            localStorage.setItem("render", 0);
          }
          setLocation("/subscripcion");
        }
        if (paquete === null) {
          setLocation("/subscripcion/paquete");
        }
      } else {
        var standardTests = [];
        var status = "";
        var days = 0;
       
        for (var l = 0; l < data.length; l++) {
            for (var n = 0; n < data[l].subjects.length; n++) {
              standardTests.push(data[l].subjects[n].standard_test_id);
              if (data[l].status === "Trial" ) {
                var fecha1 = moment(data[l].subscription_start);
                var fecha2 = moment(
                  new Date().toISOString().slice(0, 10),
                  "YYYY-MM-DD"
                );
                days = fecha1.diff(fecha2, "days");
                status = data[l].status;
              }
                for (var n = 0; n < data[l].subjects.length; n++) {
                  standardTests.push(data[l].subjects[n].standard_test_id);
                }              
            }
          }
        let resultstandardTests = standardTests.filter((item, index) => {
          return standardTests.indexOf(item) === index;
        });
        localStorage.setItem("redirect", 1);
        localStorage.setItem("header", 1);
        dispatchSubcription({
          type: types.subcription,
          payload: {
            active: 1,
            standardTest: resultstandardTests,
            status: status,
            days: days,
          },
        });
        setLocation("/dashboard");
      }
    });
  };

  return (
    <div className="login_container">
      <div>
        <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" />
        <Form
          name="login"
          className="login_container_form form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <h1 className="mb-10">{t("login")}</h1>
          <p className="mb-10">{t("contentLogin")}</p>

          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, message: t("msgemail") }]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input placeholder={t("email")} size="large" />
          </Form.Item>

          <Form.Item
            label={t("password")}
            name="password"
            rules={[{ required: true, message: t("msgpassword") }]}
            normalize={(value, prevVal, prevVals) => value.trim()}
          >
            <Input.Password placeholder={t("password")} size="large" />
          </Form.Item>

          <div className="login_remenber_container ">
            <a href={APP_IKENGA.ikengaUrl} rel="noopener noreferrer">
              <Button>{t("btnback")}</Button>
            </a>

            <Button
              type="primary"
              className="login-form-button"
              htmlType="submit"
              icon={<PoweroffOutlined />}
              loading={loadings}
            >
              {t("btn")}
            </Button>
          </div>
          <p className=" mb-10 mt-20 ">
            {t("recover")}
            <Link href="/olvido">
              <span className="link">{t("regain")}</span>
            </Link>
          </p>
          <p className="">
            {t("subscribeLogin")}{" "}
            <a href="/paquetes">
              <span className="link">{t("signin")}</span>
            </a>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Login;