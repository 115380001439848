import { Button } from 'antd'
import React,{ useContext } from 'react'
import { AuthContext } from "../../../auth/AuthContext"
import { Link } from "wouter"
import Subtitle from "../../Atoms/Subtitle/Subtitle"
import Title from '../../Atoms/Title/Title'
import MenuForHeaderEnsayoPage from '../../Molecules/MenuForHeaderPage/MenuForHeaderEnsayoPage'
import './HeaderPage.scss'

const HeaderPage = ({ contentButton, routeButton, contentTitle, contentSubtitle, withMenu}) => {
  const {
    control
  } = useContext(AuthContext);
  return (
    <>
      <div className="header-page-menu-button mb-25">
        <Button type="primary" ><Link href={routeButton} >{ contentButton }</Link></Button>
        {  withMenu &&   control.countTest !== 0 ? <MenuForHeaderEnsayoPage />: null }
      </div>
      <Title content={contentTitle} customClass="mb-25"/>
      <Subtitle h4
        content={contentSubtitle}
        customClass="c-green-primary" 
      />
    </>
  )
}

export default HeaderPage
