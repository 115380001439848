import { React, useEffect, useState, useContext } from "react";
import { Card, Modal } from "antd";
import { AuthContext } from "../../../auth/AuthContext";
import { userSubjectStatsRepo } from "../../../store";
import { RadialBar } from "@ant-design/charts";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";



const ChartsRadialAntd = () => {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { control, user, typeTest } = useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [cleanup, setCleanup] = useState();
  const [sendingRequest, setSendingRequest] = useState(false);

  const asyncFetch =  () => {
    
    if(sendingRequest ===  false){
      setSendingRequest(true);
        userSubjectStatsRepo.getUserThemeStatsColumn({
          user_id: user.userId,
          subject_id: control.subjectId,
          user_test_type: typeTest.type
        }).then(({data}) =>{
          if(data !== "[]"){
        for(var i = 0; i < data.length; i++){
          setDataList((dataList) => [
            ...dataList,
            {
              name: data[i].name,
              puntaje: Math.round(data[i].correct_percentage),
            },
          ]);  
        }
      }
      });
    }
     setCleanup(false);
    
  };
  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
      
    };
  }, []);

  if (dataList !== "[]") {
    var config = {
      width: 390,
      height: 400,
      data: dataList,
      xField: "name",
      yField: "puntaje",
      radius: 1,
      innerRadius: 0.5,
      maxAngle: 250,
      type: "line",
      xAxis: {
        title: {
          text:  t('theme'),
          style: { fill: "black", fontSize: 12 },
        },
      },
      
      tooltip: {
        tooltipContainerFillColor: "black",
        fontFamily: "Montserrat, sans-serif",
        formatter: (datum) => {
          return {
            name:  t('theme'),
            value: datum.puntaje + '%',
          };
        },
      },
    };
  }
  return (
    <Card title={t('radialAntd')} bordered={false}>
      {dataList !== "[]" ? <RadialBar {...config} /> : null}
    </Card>
  );
};

export default ChartsRadialAntd;
