import { useState } from "react";
import { Steps } from "antd";
import "./Register.scss";
import RegisterSubcriptionStep3 from "./RegisterSubcriptionStep3";
import RegisterSubcriptionStep4 from "./RegisterSubcriptionStep4";
import { useTranslation } from "react-i18next";
const { Step } = Steps;

const RegisterSubcription = () => {
  const { t } = useTranslation(); 
  const [step3Data, setStep3Data] = useState(null);
  const [step4Data, setStep4Data] = useState(null);
  const [current, setCurrent] = useState(2);
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  
  const steps = [
    {
      title: t("registro"),
      
    },
    {
      title: "E-mail", 
      
    },       
    {
      title: t("materia"),
      content: (
        <RegisterSubcriptionStep3
          next={next}
          step3Data={step3Data}
          setStep3Data={setStep3Data}
        />
      ),
    },
    {
      title: t("direccion"),
      content: (
        <RegisterSubcriptionStep4
          next={next}
          prev={prev}
          step4Data={step4Data}
          setStep4Data={setStep4Data}  
        
          step3Data={step3Data}
        />
      ),
    },
    {
      title: t("Tarjeta"),   
    },      
  ];

  return (
    <div className="register_container">
      
        {/* <Logo width={"200px"} height={"100%"} customClass="mb-10 text-center" /> */}
        <div className="box-white">
          <Steps current={current} style={{ marginRight: 100 }} responsive={"600px"}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>{steps[current].content}</div>
        </div>
      
    </div>
  );
};

export default RegisterSubcription;
