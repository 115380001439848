import { React, useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthContext";
import { Column } from "@ant-design/charts";
import { Card } from "antd";
import moment from "moment";
import { userTestRepo } from "../../../store";
import { useTranslation } from "react-i18next";

const ChartsColumnSubject = () => {
  const { t } = useTranslation();
  const { user, control,  typeTest } = useContext(AuthContext);
  const [dataList, setDataList] = useState([]);
  const [cleanup, setCleanup] = useState();
  const [sendingRequest, setSendingRequest] = useState(false);

  const asyncFetch =  () => {
    if(sendingRequest ===  false){
      setSendingRequest(true);
      userTestRepo.getStemsCorrectThemesByType({
        user_id: user.userId,
        subject_id: control.subjectId,
        type: typeTest.type
      }).then(({data})=>{
        if(data.length !== 0  ){
          for (var i = 0; i < data.length; i++) {   
            setDataList((dataList) => [
              ...dataList,
              {            
                name:  data[i].type === 'custom' ? t('modalityControl') : t('modalityEnsayo'),
                月份: data[i].name,
                月均降雨量: Math.round(data[i].sumCorrect),
              },
            ]);
          
        }
        }
      });
      
      
    setCleanup(false);
  }
  };

  useEffect(() => {
    asyncFetch();
    return () => {
      setCleanup();
      setDataList([]);
    };
  }, []);

  var config = {
    data: dataList,
    isGroup: true,
    xField: "月份",
    yField: "月均降雨量",
    seriesField: "name",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };

  return (
    <Card title={t('titlecard')} bordered={false}>
      <Column className="pxy-10" {...config} />
    </Card>
  );
};

export default ChartsColumnSubject;
