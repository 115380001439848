
export const types = {

    login:  '[auth] login',
    logout: '[auth] logout',
    avatar: '[auth] Update Avatar',    
    headerActive: '[auth] Update pageHeader active', 
    headerDesactive: '[auth] Update pageHeader desactive',  

    typeTest:'[type] New Type',    

    control:'[control] New control',    
    configuration:'[control] New configuracion',
    userTest:'[control] Add testId',
    controlCleaning:'[control] Cleaning',

    historical:'[control] New control historical',  

    subcription:'[subcription] User Subscription',
    subcriptionById:'[subcription] User Subscription',

    pageheader: '[pageheader] PageHeader display',

    result: '[result] Show Result',
    


}